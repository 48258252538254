import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { useParams } from '@reach/router';
import moment from 'moment/moment';
import { Breadcrumbs, Flex, H1, Tab, Tag } from '@decisiv/ui-components';
import iconix from '@decisiv/iconix/lib/components';
import isEmpty from 'lodash/isEmpty';
import IntegrationsService from '../../api/integrations';
import ConfiguredIntegrationsService from '../../api/configured_integrations';

import WrapperWithLoading from '../../components/WrapperWithLoading';

import IntegrationDetails from './IntegrationDetails';
import IntegrationSettings from './IntegrationSettings';
import CommandsGraph from './CommandsGraph';
import ExecutionsList from './ExecutionsList';
import ManualInvocation from './ManualInvocation';
import {
  FlexCommand,
  IntegrationDetailSection,
  IntegrationDetailTabs,
  CircuitBreakerAlertText,
} from './style';
import { encryptStorage } from '../../utils/storage';

export default function IntegrationDetailPage() {
  const [integration, setIntegration] = useState();
  const [integrationStepFunctions, setIntegrationStepFunctions] = useState();
  const [selectedTab, setSelectedTab] = useState('integration-locations-tab');
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [integrationEnabledStatus, setIntegrationEnabledStatus] =
    useState(false);
  const [integrationPublishStatus, setIntegrationPublishStatus] =
    useState(false);
  const [locationLoading, setLocationLoading] = useState(true);
  const params = useParams();
  const [displayCircuitBreaker, setDisplayCircuitBreaker] = useState(false);
  const userSession = encryptStorage.getItem('userSession');

  const checkGlobalSettings = (globalSettings) => {
    let isValid = true;
    Object.entries(globalSettings).forEach((globalSetting) => {
      if (globalSetting[1].required) {
        if (isEmpty(globalSetting[1].value)) {
          isValid = false;
        }
      }
    });

    return isValid;
  };

  // eslint-disable-next-line consistent-return
  const setTab = (fetchedIntegration) => {
    if (window.history.state?.fromEdit === true) {
      return setSelectedTab('integration-settings-tab');
    }

    if (userSession['is_global_admin?']) {
      if (
        fetchedIntegration.attributes.enabled &&
        checkGlobalSettings(
          fetchedIntegration?.attributes?.global_settings_attributes,
        )
      ) {
        setSelectedTab('integration-locations-tab');
      } else {
        setSelectedTab('integration-settings-tab');
      }
    } else {
      setSelectedTab('integration-locations-tab');
    }
  };

  const updateIntegrationStatus = (value, integrationField) => {
    const currentIntegration = integration;
    currentIntegration.attributes[integrationField] = value;
    setIntegrationEnabledStatus(currentIntegration.attributes.enabled);
    setIntegrationPublishStatus(currentIntegration.attributes.published);
    setIntegration(currentIntegration);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      setLocationLoading(true);
      const fetchSingleResponse =
        await IntegrationsService.fetchIntegrationByUuid(params.id);
      setIntegration(fetchSingleResponse.data);
      setIntegrationStepFunctions(fetchSingleResponse.included);
      setLoading(false);
      setTab(fetchSingleResponse.data);

      setIntegrationEnabledStatus(fetchSingleResponse.data.attributes.enabled);
      setIntegrationPublishStatus(
        fetchSingleResponse.data.attributes.published,
      );

      const fetchListResponse =
        await ConfiguredIntegrationsService.fetchConfiguredIntegrations(
          params.id,
          1,
          50,
        );

      if (
        fetchSingleResponse.data.attributes.circuit_breaker_enabled === true &&
        fetchSingleResponse.data.attributes.circuit_breaker_opened_at
      ) {
        const circuitBreakerOpenedAt = moment(
          fetchSingleResponse.data.attributes.circuit_breaker_opened_at,
        );

        const circuitBreakerEndDate = circuitBreakerOpenedAt.add(
          fetchSingleResponse.data.attributes.circuit_breaker_retry_after,
          's',
        );

        if (circuitBreakerEndDate.isAfter(moment())) {
          setDisplayCircuitBreaker(true);
        }
      }

      setLocations(fetchListResponse.data);
      setLocationLoading(false);
    };

    getData();
  }, [params.id, refresh]);

  return (
    <>
      <Flex padding={1}>
        {integration && (
          <Breadcrumbs
            path={[{ to: '/integrations', text: 'Integrations' }]}
            current={integration.attributes.name}
          />
        )}
      </Flex>
      <IntegrationDetailSection
        flexDirection="column"
        justifyContent="center"
        padding={2}
        flex="none"
        flexGrow="0"
      >
        <WrapperWithLoading loading={loading}>
          <Flex>
            {integration && <H1>{integration.attributes.name}</H1>}
            <Flex padding={1}>
              {integration && (
                <>
                  <Tag
                    text={integrationEnabledStatus ? 'Active' : 'Inactive'}
                    icon={iconix.CircleF}
                    color={integrationEnabledStatus ? 'success' : 'secondary'}
                    variant="outline"
                  />
                  <Tag
                    style={{
                      'margin-left': '0.6rem',
                    }}
                    text={
                      integrationPublishStatus ? 'Published' : 'Unpublished'
                    }
                    icon={
                      integrationPublishStatus ? iconix.EyeAlt : iconix.EyeSlash
                    }
                    color={integrationPublishStatus ? 'success' : 'secondary'}
                    variant="outline"
                  />
                  {displayCircuitBreaker && (
                    <Flex>
                      <Tag
                        style={{
                          'margin-left': '0.6rem',
                        }}
                        text="Circuit Breaker"
                        icon={iconix.Bolt}
                        color="danger"
                      />
                      <CircuitBreakerAlertText>
                        <Trans>Circuit Breaker activated on: </Trans>
                        {moment(
                          integration.attributes.circuit_breaker_opened_at,
                        ).format('MM/DD/YYYY hh:mm:ss a z')}
                      </CircuitBreakerAlertText>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </WrapperWithLoading>
      </IntegrationDetailSection>
      <WrapperWithLoading loading={locationLoading}>
        <IntegrationDetailTabs
          renderHiddenTabsContent={false}
          size="large"
          defaultSelectedId={selectedTab}
        >
          <Tab
            title="Locations"
            name="Locations"
            id="integration-locations-tab"
            disabled={false}
          >
            <IntegrationDetails
              locations={locations}
              integration={integration}
              setRefresh={setRefresh}
            />
          </Tab>
          <Tab
            title="Global Settings"
            name="Global Settings"
            id="integration-settings-tab"
            disabled={!userSession['is_global_admin?']}
          >
            {integration && (
              <IntegrationSettings
                integration={integration}
                updateIntegrationStatus={updateIntegrationStatus}
              />
            )}
          </Tab>

          <Tab
            title="Commands"
            name="Commands"
            id="integration-commands-tab"
            disabled={false}
          >
            <Flex padding={1}>
              {integrationStepFunctions?.map(
                (stepFunction) =>
                  stepFunction?.type === 'integration_step_functions' && (
                    <>
                      {integration && integrationStepFunctions && (
                        <FlexCommand>
                          <CommandsGraph
                            name={integration.attributes.name}
                            startAt={
                              stepFunction.attributes?.step_function_definition
                                ?.StartAt
                            }
                            states={
                              stepFunction.attributes?.step_function_definition
                                ?.States
                            }
                          />
                        </FlexCommand>
                      )}
                    </>
                  ),
              )}
            </Flex>
          </Tab>

          <Tab
            title="Executions"
            name="executions"
            id="integration-executions-tab"
            disabled={false}
          >
            <ExecutionsList integration={integration} />
          </Tab>

          <Tab
            title="Manual Execution"
            name="manual-invocations"
            id="manual-invocations-tab"
            disabled={false}
          >
            <ManualInvocation integration={integration} />
          </Tab>
        </IntegrationDetailTabs>
      </WrapperWithLoading>
    </>
  );
}
